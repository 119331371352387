@import '../../../../common-assets/styles/variables/color';
@import '../../../../common-assets/styles/utilities/functions';

.fileNumber {
  color: $primary-main;
  text-decoration: underline;
  cursor: pointer;
}

.files-list-grid {
  display: flex;
  flex-direction: column;

  .ag-header-cell .ag-header-active .ag-header-cell-menu-button {
    display: none;
  }
  .ag-theme-alpine .ag-select .ag-picker-field-display {
    text-align: left;
  }
  .ag-theme-alpine .ag-menu.ag-popup-child {
    min-width: 370px;
  }
}

.ag-theme-alpine .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border-left-color: #e0e0e0 !important;
}

.ag-column-first.ag-cell-last-left-pinned,
.ag-pinned-left-header {
  border-right: 1px solid #e0e0e0 !important;
}

.ag-theme-alpine .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) svg {
  cursor: pointer;
}

.ag-theme-alpine
  .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
  .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
  overflow: hidden;
}

.refine-search {
  color: $primary-light;
  font-size: 14px;
  margin-top: -5px;
  cursor: pointer;
}

.files-container {
  width: 95%;
  margin: auto;

  &--header {
    text-align: left;
  }

  &--spinner-root {
    min-height: 25px;
  }

  &--alert {
    position: fixed;
    margin-left: calc(50% - 16px);
    transform: translateX(-50%);
    z-index: 1;
  }

  .no-rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .no-files {
      width: 100px;
      height: 100px;
    }
    h1 {
      color: $text-secondary;
    }
    .adjust-your-search {
      width: 265px;
      color: $text-secondary;
    }
  }
}

.ag-center-cols-container .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
  font-size: pxToRem(24);
}

.ag-sort-indicator-container > span:first-child {
  display: none;
}

.ag-input-field-input {
  &:focus {
    border: var(--ag-input-border);
    box-shadow: none;
  }
}

.form-section-container {
  margin-bottom: pxToRem(2);
}

.MuiMenuItem-root[data-option='AgentNetDropdownSelector'] {
  min-height: 35px;
}

.savingFailedErrorHeading {
  font-weight: 500;
  font-size: 16px;
}
.savingFailedErrorList {
  padding-left: 24px;
  margin: 0;
  font-size: 14px;
}

.MuiAlert-standardError.snackbar-message {
  .MuiAlert-icon {
    color: #b3150a;
  }
}

.MuiFormHelperText-root.Mui-error {
  color: #AC1209 !important;
}

.create-order-form-container .edit-order-form-container .MuiFormHelperText-contained.Mui-error.Mui-required {
  margin-left: 14px;
}

.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon {
  height: inherit !important;
}
